.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  font-family: "Arial", sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginPage {
  background-color: #111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginPage .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  width: 100vw;
  margin: 0;
  padding: 0;
}

.loginPage .logo {
  max-width: 300px;
  margin-bottom: 30px;
}

.loginPage input {
  display: block;
  width: calc(100% - 20px);
  border: 0;
  margin: 10px;
  padding: 20px 10px;
  font-size: 16px;
  color: #666;
}

input:focus {
  outline: none;
}

.loginPage .buttonLogin {
  background: #21aeef;
  text-align: center;
  color: #fff;
  width: 100%;
  font-weight: bold;
  padding: 15px 0;
  cursor: pointer;
}

.container {
  margin-left: 250px;
  padding: 20px;
}

.header {
  text-align: right;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar .logo {
  max-width: 80%;
  padding: 20px;
}

.sidebar .menuLink {
  font-size: 20px;
  color: #fff;
  padding: 20px;
  margin: 0;
}

.dbStat {
  color: #ccc;
  margin-bottom: 30px;
  padding: 0 20px;
}

.sectionCont {
  padding: 40px 0;
}

.countersContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.counterCont {
  flex: 1;
  margin: 0 10px ;
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.counterCont .count {
  font-size: 30px;
  font-weight: bold;
}

.counterCont .label {
  font-size: 24px;
}

.counterCont .icon {
  font-size: 40px;
}

.counterCont .add {
  cursor: pointer;
}

.sourcesCounter {
  background: #813ef6;
  background: -moz-linear-gradient(left, #813ef6 0%, #43baf2 100%);
  background: -webkit-linear-gradient(left, #813ef6 0%, #43baf2 100%);
  background: linear-gradient(to right, #813ef6 0%, #43baf2 100%);
}

.regionsCounter {
  background: #6cb372;
  background: -moz-linear-gradient(left, #6cb372 0%, #1de7b4 100%);
  background: -webkit-linear-gradient(left, #6cb372 0%, #1de7b4 100%);
  background: linear-gradient(to right, #6cb372 0%, #1de7b4 100%);
}

.tagsCounter {
  background: #f9775f;
  background: -moz-linear-gradient(left, #f9775f 0%, #dd2475 100%);
  background: -webkit-linear-gradient(left, #f9775f 0%,#dd2475 100%);
  background: linear-gradient(to right, #f9775f 0%,#dd2475 100%);
}

.titleSection {
 font-size: 32px;
 border-left: 5px solid #f37920;
 padding-left: 20px 
}

.gridSources {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sourceCard{
  position: relative;
  cursor: pointer;
}

.sourceLogoCont {
  height: 150px;
  background-color: #fff;
  text-align: center;
  overflow: hidden;
  padding: 10px
}

.sourceLogo {
  max-width: 100%;
  height: 150px;
  object-fit: contain;
}

.sourceName {
  padding: 0 10px;
  font-size: 18px;
  color: #111;
  font-weight: bold;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.sourceControl {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.sourceCard:hover .sourceControl {
  visibility: visible;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.titleContainer .add {
  color: #21aeef;
  cursor: pointer;
}

.filterContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 4px rgba(0,0,0,0.1);
  padding: 20px;
  margin-bottom: 30px;
}

.filterContainer h2 {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 400;
}

.filterContainer input {
  width: 30%;
  font-size: 20px;
  padding:0 30px 5px 30px;
  border: 0;
  border-bottom: 1px solid #cecece;
}

.listItem {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #cecece;
}

.listItem .itemName {
  color: #666;
  text-transform: capitalize;
  font-size: 18px;
}

.listItem .itemControl {
  display: flex;
}

.titleDetail {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.formDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.dataCol {
  width: 60%;
}

.dataCol label {
  display: block;
  font-size: 14px;
  color: #666;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 10px;
}

.dataCol input,
.dataCol textarea {
  display: block;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #cecece;
  padding: 10px 5px;
  font-size: 20px;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
}

.dataCol textarea {
  resize: none;
  font-size: 18px;
  height: 150px
}

.showCol {
  max-width: 250px;
}

.showCol .logoSource {
  width: 250px;
  height: 250px;
  margin-bottom: 30px;
  object-fit: contain;
  background-color: #fff;
}

.showCol .submitButton {
  text-align: center;
  color: #fff;
  background-color: #37AEEF;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.showCol .deleteButton {
  text-align: center;
  color: #fff;
  background-color: #F45469;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.editItem {
  width: 90%;
  border: 0;
  border-bottom: 1px solid #cecece;
  padding: 5px 10px;
  font-size: 18px;
}

@media screen and (max-width: 992px) {
  .countersContainer,
  .titleContainer,
  .formDetail {
    flex-direction: column;
  }
  .titleContainer {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .container {
    margin-left: 0;
  }
  .sidebar {
    display: none;
  }
  .header {
    display: none;
  }
  .header-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-mobile .logo {
    max-width: 100%;
  }
  .sidebar-mobile {
    position: fixed;
    top: 0;
    right: 0;
    width: 80vw;
    height: 100%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 99999;
  }
  .sidebar-mobile .logo {
    max-width: 80%;
    padding: 20px;
  }
  .sidebar-mobile .menuLink {
    font-size: 20px;
    color: #fff;
    padding: 20px;
    margin: 0;
  }
  .filterContainer input {
    width: calc(100% - 60px)
  }
  .dataCol {
    width: 100%
  }
  .showCol {
    max-width: 100%
  }
  .showCol .logoSource {
    width: 90vw;
  }
  .overlay {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,0.8);
    z-index: 3;
  }
}